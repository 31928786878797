import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';



const UpdateNotification = ({ open, onUpdate, onClose }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      message="A new version of the app is available."
      action={
        <Button color="secondary" size="small" onClick={onUpdate}>
          Update Now
        </Button>
      }
      onClose={onClose}
    />
  );
};

export default UpdateNotification;