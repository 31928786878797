import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@material-ui/core";

const UpdateNotification = ({ onUpdate }) => {
  const { t } = useTranslation("app");

  return (
    <Dialog
      open={true}
      aria-labelledby="update-dialog-title"
      BackdropProps={{ invisible: true }} 
      onClose={() => {}} 
      disableEscapeKeyDown={true}
    >
      <DialogContent>
        <Typography variant="body1">
        {t("updateAvailableMessage")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={onUpdate}
        >
          {t("updateNow")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UpdateNotification.propTypes = {
  onUpdate: PropTypes.func.isRequired,
};

export default UpdateNotification;
