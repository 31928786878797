import { Workbox } from 'workbox-window';
import logger from 'debug';
import React from 'react';
import ReactDOM from 'react-dom';
import UpdateNotification from './updateNotification';

const registerSW = async () => {
  const wb = new Workbox('./service-worker.js');
  let registration;

  wb.addEventListener('waiting', (event) => {
    logger('app:info')('A new service worker has been installed and is waiting to activate.');
    wb.messageSW({ type: 'SKIP_WAITING' });
  });

  wb.addEventListener('controlling', () => {
    logger('app:info')('New service worker has taken control. Reloading page.');
    window.location.reload();
  });

  wb.addEventListener('activated', () => {
    logger('app:info')('Service worker activated and ready.');
  });

  // Broadcast channel to notify other tabs
  wb.addEventListener('controlling', () => {
    const broadcast = new BroadcastChannel('sw-messages');
    broadcast.postMessage({ type: 'UPDATE_SUCCESS' });
    window.location.reload(); 
  });
  
  // receive postMessage from SW (ie data was sync'd)
  wb.addEventListener('message', (event) => {
    logger('app:info')('Message received: ', event.data);
    const { name, date } = event.data;
    const syncEvent = new CustomEvent(name, { detail: { date } });
    window.dispatchEvent(syncEvent);
  });

  // empty the replay queue, which holds our offline wasteEntry records
  window.addEventListener('REPLAY_REQUESTS', () => {
    wb.messageSW({ type: 'REPLAY_REQUESTS' });
  });

  // Register the service worker
  try {
    registration = await wb.register();
  } catch (error) {
    logger('app:error')('Service worker registration failed:', error);
  }

  // Check for updates every 30 minutes
  setInterval(async () => {
    logger('app:info')('Checking for updates...');
    if (registration) {
      try {
        await registration.update();
        logger('app:info')('Update check completed');
      } catch (error) {
        logger('app:error')('Update check failed:', error);
      }
    }
  }, 30 * 60 * 1000);
};

export default registerSW;